import actionReporter from "~/config/initializers/actionReporter";
import { FeatureFlagEnum } from "../graphql/codegen/graphql";
import AppContext from "~/AppContext";

export const isFeatureEnabled = (flagName: FeatureFlagEnum) => {
  const featureFlags = AppContext.featureFlags;
  const found = featureFlags[flagName];

  if (!found) {
    const message = `Feature flag ${flagName} not found in the returned list`;
    console.error(message);
    actionReporter.notify(new Error(message), { context: { data: featureFlags } });
  }
  return found?.enabled || false;
};

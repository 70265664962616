import Honeybadger from "@honeybadger-io/js";
import {
  appEnv,
  commitSha,
  devLogger,
  honeybadgerApiKey,
  honeybadgerEnv,
  isLocal,
} from "~/config/env";

let honeybadger: Honeybadger | undefined;

if (isLocal) {
} else {
  honeybadger = Honeybadger.configure({
    apiKey: honeybadgerApiKey,
    environment: honeybadgerEnv || appEnv,
    revision: commitSha,
    breadcrumbsEnabled: true,
    reportData: !isLocal,
    debug: false,
    ...(isLocal
      ? {
          logger: devLogger,
        }
      : {}),
  });

  Honeybadger.beforeNotify((notice) => {
    if (!notice) return false;

    const excludedNames = [
      "InteractionRequiredAuthError",
      "BrowserAuthError",
      "ClientAuthError",
    ];
    if (excludedNames.some((name) => new RegExp(name).test(notice?.name))) {
      return false;
    }

    if (notice?.status) {
      notice.context.status = notice.status;
    }
  });
}

export default honeybadger;

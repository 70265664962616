// import * as Sentry from "@sentry/react";
import Honeybadger from "@honeybadger-io/js";

import React, { createContext, useContext } from "react";

import { HoneybadgerErrorBoundary } from "./Honeybadger";

import honeybadger from "~/config/initializers/honeybadger";
import { isLocal } from "~/config/env";
// import sentry from "~/config/initializers/sentry";

class LocalReporter {
  notify(message: string, { context }: { context?: Record<string, any> } = {}) {
    console.log("LocalReporter notify", message, context);
  }

  setContext(context: Record<string, any>) {
    console.log("LocalReporter setContext", context);
  }

  resetContext() {
    console.log("LocalReporter resetContext");
  }

  errorBoundary({ children }: { children: React.ReactNode }): any {
    return children;
  }
}

class HoneybadgerReporter {
  private instance: Honeybadger;

  constructor() {
    this.instance = honeybadger;
  }

  notify(message: string, { context }: { context?: Record<string, any> } = {}) {
    this.instance.notify(message, { context });
  }

  setContext(context: Record<string, any>) {
    this.instance.setContext(context);
  }

  resetContext() {
    this.instance.clear();
  }

  errorBoundary({ children }: { children: React.ReactNode }): any {
    return (
      <HoneybadgerErrorBoundary honeybadger={this.instance}>
        {children}
      </HoneybadgerErrorBoundary>
    );
  }
}

// class SentryReporter {
//   private instance: any;

//   constructor() {
//     this.instance = sentry;
//   }

//   notify(message: string, { context }: { context?: Record<string, any> } = {}) {
//     return this.instance.captureException(message, { context });
//   }

//   setContext(context: Record<string, any>) {
//     return Object.keys(context).map((key) => {
//       this.instance.setContext(key, context[key]);
//     });
//   }

//   resetContext() {
//     return this.instance.getCurrentScope().clear();
//   }

//   errorBoundary({ children }: { children: React.ReactNode }): any {
//     return <Sentry.ErrorBoundary>{children}</Sentry.ErrorBoundary>;
//   }
// }

type TService = "honeybadger" | "local";

class ActionReporter {
  private reporter: HoneybadgerReporter | LocalReporter;

  constructor(service: TService) {
    if (isLocal) {
      this.reporter = new LocalReporter();
    } else {
      switch (service) {
        case "local":
          this.reporter = new LocalReporter();
          break;
        // case "sentry":
        //   this.reporter = new SentryReporter();
        //   break;
        case "honeybadger":
          this.reporter = new HoneybadgerReporter();
          break;
        default:
          throw new Error(`Unknown service: ${service}`);
      }
    }
  }

  notify(message: string, { context }: { context?: Record<string, any> } = {}) {
    return this.reporter.notify(message, { context });
  }

  setContext(context: Record<string, any>) {
    return this.reporter.setContext(context);
  }

  resetContext() {
    return this.reporter.resetContext();
  }

  errorBoundary({
    worker,
    children,
  }: {
    worker: ActionReporter;
    children: React.ReactNode;
  }): any {
    return worker.reporter.errorBoundary({ children });
  }
}

export default ActionReporter;

export const ActionReporterContext = createContext<ActionReporter>(
  new ActionReporter("local")
);
export const useActionReporter = () => useContext(ActionReporterContext);

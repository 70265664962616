import Honeybadger from "@honeybadger-io/js";

import React from "react";
import PropTypes from "prop-types";

// eslint-disable-next-line @typescript-eslint/ban-types
class DefaultErrorComponent extends React.Component {
  render() {
    return React.createElement(
      "div",
      { className: "error" },
      React.createElement("div", null, "An Error Occurred"),
      React.createElement(
        "div",
        null,
        JSON.stringify(this.props.error, null, 2)
      ),
      React.createElement(
        "div",
        null,
        this.props.info ? JSON.stringify(this.props.info, null, 2) : ""
      )
    );
  }
}
DefaultErrorComponent.propTypes = {
  error: PropTypes.object,
  info: PropTypes.object,
};

class HoneybadgerErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      info: null,
      errorOccurred: false,
    };
    this.props.honeybadger.afterNotify((error, _notice) => {
      if (!error && this.props.showUserFeedbackFormOnError) {
        this.props.honeybadger.showUserFeedbackForm();
      }
    });
  }
  static getDerivedStateFromError(error) {
    return { error: error, errorOccurred: true, info: null };
  }
  componentDidCatch(error, errorInfo) {
    this.setState({ errorOccurred: true, error: error, info: errorInfo });
    this.props.honeybadger.notify(error, { context: errorInfo });
  }
  getErrorComponent() {
    return this.props.ErrorComponent
      ? React.createElement(this.props.ErrorComponent, this.state)
      : React.createElement(DefaultErrorComponent, { ...this.state });
  }
  render() {
    return React.createElement(
      React.Fragment,
      null,
      this.state.errorOccurred ? this.getErrorComponent() : this.props.children
    );
  }
}
HoneybadgerErrorBoundary.propTypes = {
  honeybadger: PropTypes.object.isRequired,
  showUserFeedbackFormOnError: PropTypes.bool,
  children: PropTypes.element,
  ErrorComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
};
HoneybadgerErrorBoundary.defaultProps = {
  showUserFeedbackFormOnError: false,
};

const NOTIFIER = {
  name: "@honeybadger-io/react",
  url: "https://github.com/honeybadger-io/honeybadger-js/tree/master/packages/react",
  version: "6.1.23",
};
Honeybadger.setNotifier(NOTIFIER);

export { HoneybadgerErrorBoundary };
